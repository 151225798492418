import { useCallback, useState } from 'react';
import useStyles from '../styles';
import { cx } from '../../../utils/exports';
import useOuterClick from '../hooks/useOuterClick';

interface Props {
  id: string;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  value: string;
}

const YearsDropdown = ({ id, onChange, options, value }: Props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const yearsRef = useOuterClick<HTMLUListElement>((e) => {
    if (isOpen && e.target.id !== id) setIsOpen(false);
  });
  const toggleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  return (
    <button
      className={classes.yearsBtn}
      id={id}
      onClick={toggleDropdown}
      type="button"
    >
      {value}
      {options.length && (
        <ul
          ref={yearsRef}
          className={cx(classes.yearsDropdown, isOpen && 'is-active')}
        >
          {options.map((option) => (
            <li>
              <button
                onClick={() => {
                  toggleDropdown();
                  onChange(option.value);
                }}
                type="button"
              >
                {option.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </button>
  );
};

export default YearsDropdown;
