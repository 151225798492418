import { breakpoints, color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  pickerOptions: {
    background: '#fff',
    boxShadow: '0 10px 15px rgba(0, 0, 0, 0.0451483)',
    borderRadius: '5px',
    maxWidth: '324px',
    minHeight: 250,
    zIndex: 999,
    display: 'none',
    position: 'absolute',
    padding: 12,
    '&.is-active': {
      display: 'block',
    },
  },
  yearSwitcher: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    '& .form-control': {
      marginBottom: 0,
      textAlign: 'center',
      marginRight: 0,
    },
    '& button': {
      cursor: 'pointer',
      '&:disabled': {
        cursor: 'not-allowed',
      },
    },
    '& .choices': {
      marginLeft: -5,
      marginTop: -5,
      '& .choices__inner': {
        border: 'none',
      },
      '& .choices__list--single': {
        paddingRight: 0,
      },
      '& .choices__list--dropdown': {
        marginLeft: -15,
        maxHeight: 200,
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  months: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& button': {
      cursor: 'pointer',
      display: 'flex',
      width: 'calc(100% / 3)',
      height: 48,
      justifyContent: 'center',
      alignItems: 'center',
      '&:disabled': {
        color: color('grey', 300),
        cursor: 'not-allowed',
      },
      '&:hover': {
        '&:not(:disabled)': {
          border: `2px solid ${color('primary')}`,
          borderRadius: 3,
        },
      },
      '&.is-active': {
        background: color('primary'),
        color: 'white',
        border: 'none',
        borderRadius: 3,
      },
      '&:nth-child(even)': {
        // margin: `0 3px`,
      },
    },
    '&': {
      [`@media screen and (max-width: ${breakpoints.m}px)`]: {
        height: 40,
      },
    },
  },
  input: {
    minHeight: `${getRem(48)} !important`,
    padding: `0.42857rem 0 0.42857rem 0.85714rem !important`,
    '& ~ .input__icon': {
      display: 'none',
    },
    '&': {
      [`@media screen and (max-width: ${breakpoints.m}px)`]: {
        minHeight: `${getRem(40)} !important`,
      },
    },
  },
  periodWrapper: {
    '& .form-control': {
      marginBottom: 0,
    },
  },
  multiPickerWrapper: {
    position: 'relative',
    '&.is-active': {
      '& .input-addons__item svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  multipickerInput: {
    minHeight: '100%',
    padding: '0.75rem 0 0.75rem 1rem !important',
  },
  multiPickerOptions: {
    background: '#fff',
    boxShadow: '0 10px 15px rgba(0, 0, 0, 0.0451483)',
    borderRadius: '5px',
    width: '100%',
    maxWidth: '324px',
    minHeight: getRem(250),
    zIndex: 999,
    display: 'none',
    position: 'absolute',
    padding: 0,
    top: getRem(86),
    transition: 'visibility 0.1s ease-out 0.1s, opacity 0.1s ease-out 0s',
    '&.is-active': {
      display: 'block',
    },
    '& .form-control': {
      marginBottom: 0,
    },
    '& .radiocheck-group': {
      overflowY: 'auto',
      maxHeight: getRem(300),
      margin: 0,
    },
    '& .form-control--checkbox': {
      padding: `0 ${getRem(16)}`,
      '& .checkbox': {
        alignItems: 'center',
      },
      '& .radiocheck__label': {
        padding: `${getRem(10)} 0`,
      },
      margin: 0,
      '&:hover': {
        '& .radiocheck__label': {
          padding: `${getRem(9)} 0`,
        },
        backgroundColor: color('primary', 100),
        borderTop: `1px solid ${color('primary', 600)}`,
        borderBottom: `1px solid ${color('primary', 600)}`,
      },
    },
  },
  optionsBtnWrapper: {
    position: 'relative',
    padding: `${getRem(16)}`,
    '& button': {
      width: '100%',
    },
  },
  inputChevron: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  yearsBtn: {
    position: 'relative',
    color: color('primary'),
    fontSize: getRem(18),
  },
  yearsDropdown: {
    position: 'absolute',
    display: 'none',
    listStyle: 'none',
    border: '1px solid #e4e5e5',
    borderRadius: getRem(8),
    paddingLeft: 0,
    left: getRem(-16),
    overflowY: 'auto',
    maxHeight: getRem(120),
    '& button': {
      padding: `${getRem(12)} ${getRem(16)}`,
      backgroundColor: 'white',
      borderRadius: getRem(8),
      '&:hover': {
        backgroundColor: color('primary', 100),
      },
    },
    '&.is-active': {
      display: 'block',
    },
  },
});
